import React, { useState } from 'react';
import { Box } from '@mui/system';
import { TextField, Typography, Button } from '@mui/material';

const Contact = ({ colors }) => {
    const { ColorMorado, ColorGris, ColorHueso, ColorMarino } = colors;

    const [charCount, setCharCount] = useState(0);
    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        mensaje: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Actualiza el estado del campo correspondiente
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Actualiza el contador de caracteres para el mensaje
        if (name === 'mensaje' && value.length <= 400) {
            setCharCount(value.length);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Validación simple antes de enviar
            if (!formData.nombre || !formData.correo || !formData.mensaje) {
                alert('Todos los campos son obligatorios.');
                return;
            }

            // Envío de datos al servidor
            const response = await fetch('D:/Programas Instalados/XAMP-php-7-4/htdocs/react-dashboard/server/SendEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                alert('Mensaje enviado exitosamente.');
                // Limpia el formulario después de enviar
                setFormData({
                    nombre: '',
                    correo: '',
                    mensaje: '',
                });
                setCharCount(0);
            } else {
                alert(result.message || 'Error al enviar el mensaje.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Hubo un error al enviar el mensaje.');
            alert(error);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    minHeight: '600px',
                    bgcolor: ColorMorado,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        width: { xs: '100%', md: '50%' },
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 4,
                        gap: 3,
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Typography
                            component="div"
                            align="justify"
                            gutterBottom
                            color={ColorGris}
                            sx={{
                                typography: { xs: 'h6', md: 'h5' },
                            }}
                        >
                            <h2>¡Hola!</h2>
                            Soy José Eduardo, Programador Web, con experiencia en FrontEnd y BackEnd en el desarrollo de soluciones eficientes. Mi enfoque se centra en la calidad del código, la usabilidad y la escalabilidad.
                        </Typography>
                    </Box>

                    {/* Nombre */}
                    <TextField
                        id="nombre"
                        name="nombre"
                        label="Nombre Completo"
                        placeholder="Nombre"
                        fullWidth
                        value={formData.nombre}
                        onChange={handleChange}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&:hover fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: ColorHueso,
                                },
                                "& input": {
                                    color: ColorHueso,
                                },
                            },
                            "& .MuiInputLabel-root": {
                                color: ColorHueso,
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: ColorGris,
                            },
                        }}
                    />

                    {/* Correo */}
                    <TextField
                        id="correo"
                        name="correo"
                        label="Correo Electrónico"
                        placeholder="Correo"
                        fullWidth
                        value={formData.correo}
                        onChange={handleChange}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&:hover fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: ColorHueso,
                                },
                                "& input": {
                                    color: ColorHueso,
                                },
                            },
                            "& .MuiInputLabel-root": {
                                color: ColorHueso,
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: ColorGris,
                            },
                        }}
                    />

                    {/* Mensaje */}
                    <TextField
                        id="mensaje"
                        name="mensaje"
                        label={`Mensaje (${charCount}/400 caracteres)`}
                        placeholder="Mensaje"
                        multiline
                        rows={4}
                        fullWidth
                        value={formData.mensaje}
                        onChange={handleChange}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&:hover fieldset": {
                                    borderColor: ColorGris,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: ColorHueso,
                                },
                                "& textarea": {
                                    color: ColorHueso,
                                    textAlign: 'justify',
                                    wordSpacing: '4px',
                                    letterSpacing: '2px',
                                },
                            },
                            "& .MuiInputLabel-root": {
                                color: ColorHueso,
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: ColorGris,
                            },
                        }}
                    />

                    <Button
                        variant="contained"
                        color="success"
                        size="large"
                        type="submit"
                    >
                        Enviar Correo
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Contact;

// import React from 'react';
// import { Box } from '@mui/system';
// import { TextField, Typography, Button } from '@mui/material';


// const Contact = ({ colors }) => {
//     const [charCount, setCharCount] = React.useState(0);
//     const [message, setMessage] = React.useState(""); // Para almacenar el mensaje

//     const handleChange = (e) => {
//         if (e.target.value.length <= 400) {
//             setMessage(e.target.value); // Actualiza el mensaje solo si no excede 200 caracteres
//             setCharCount(e.target.value.length); // Actualiza el contador de caracteres
//         }
//     };
//     const { ColorMorado, ColorGris, ColorHueso, ColorMarino } = colors;
//     const correo = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]:
//                 e.target.value
//         });
//     };
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await fetch('https://tu-dominio.com/send_email.php', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(formData)
//             });
//             const result = await response.json();
//             alert(result.message);
//         } catch (error) {
//             console.error('Error:', error);
//             alert('Hubo un error al enviar el mensaje.');
//         }
//     };
// };

// return (
//     <>
//         <Box sx={{
//             width: '100%',
//             height: 'auto',
//             minHeight: '600px',
//             bgcolor: ColorMorado,
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//         }}>
//             <Box component='form' onSubmit={handleSubmit} noValidate
//                 sx={{
//                     width: { xs: '100%', md: '50%' },
//                     display: 'flex',
//                     flexWrap: 'wrap',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     p: 4,
//                     gap: 3, // Espaciado entre los elementos
//                 }}
//             >
//                 <Box
//                     sx={{
//                         width: '100%'
//                     }}>
//                     <Typography
//                         // variant="h5" 
//                         component="div"
//                         align="justify"
//                         gutterBottom
//                         color={ColorGris}
//                         sx={{
//                             typography: { xs: 'h6', md: 'h5' }
//                         }}>
//                         <h2>¡Hola! </h2>Soy José Eduardo, Programador Web, con experiencia en FrontEnd y BackEnd en el desarrollo de soluciones eficientes. Mi enfoque se centra en la calidad del código, la usabilidad y la escalabilidad.
//                         {/* Soy José Eduardo, Programador Web, con experiencia en FrontEnd y BackEnd en el desarrollo de soluciones eficientes, convirtiendo ideas en en sistemas funcionales.
//                     Soy José Eduardo, Programador Web dedicado a crear soluciones eficientes y funcionales. Mi enfoque se centra en la calidad del código, la usabilidad y la satisfacción del cliente */}
//                     </Typography>
//                 </Box>
//                 {/* Nombre */}
//                 <TextField
//                     id="nombre"
//                     label="Nombre Completo"
//                     placeholder="Nombre"
//                     fullWidth
//                     sx={{
//                         "& .MuiOutlinedInput-root": {
//                             "& fieldset": {
//                                 borderColor: ColorGris, // Color del borde normal
//                             },
//                             "&:hover fieldset": {
//                                 borderColor: ColorGris, // Color del borde al pasar el mouse
//                             },
//                             "&.Mui-focused fieldset": {
//                                 borderColor: ColorHueso, // Color del borde en focus
//                             },
//                             "& input": {
//                                 color: ColorHueso, // Color del texto al escribir
//                             },
//                         },
//                         "& .MuiInputLabel-root": {
//                             color: ColorHueso, // Color del label
//                         },
//                         "& .MuiInputLabel-root.Mui-focused": {
//                             color: ColorGris, // Color del label en focus
//                         },
//                     }}
//                 />
//                 {/* Correo */}
//                 <TextField
//                     id="correo"
//                     label="Correo Electrónico"
//                     placeholder="Correo"
//                     fullWidth
//                     sx={{
//                         "& .MuiOutlinedInput-root": {
//                             "& fieldset": {
//                                 borderColor: ColorGris, // Color del borde normal
//                             },
//                             "&:hover fieldset": {
//                                 borderColor: ColorGris, // Color del borde al pasar el mouse
//                             },
//                             "&.Mui-focused fieldset": {
//                                 borderColor: ColorHueso, // Color del borde en focus
//                             },
//                             "& input": {
//                                 color: ColorHueso, // Color del texto al escribir
//                             },
//                         },
//                         "& .MuiInputLabel-root": {
//                             color: ColorHueso, // Color del label
//                         },
//                         "& .MuiInputLabel-root.Mui-focused": {
//                             color: ColorGris, // Color del label en focus
//                         },
//                     }}
//                 />
//                 <TextField
//                     id="texto"
//                     label={`Mensaje ${charCount}/400 caracteres.`}
//                     placeholder='Ménsaje'
//                     multiline
//                     rows={4}
//                     fullWidth
//                     value={message} // Asocia el valor con el estado `message`
//                     onChange={handleChange} // Usa la función `handleChange` para manejar el evento
//                     sx={{
//                         "& .MuiOutlinedInput-root": {
//                             "& fieldset": {
//                                 borderColor: ColorGris,
//                             },
//                             "&:hover fieldset": {
//                                 borderColor: ColorGris,
//                             },
//                             "&.Mui-focused fieldset": {
//                                 borderColor: ColorHueso,
//                             },
//                             "& textarea": {
//                                 color: ColorHueso,
//                                 textAlign: "justify",
//                                 wordSpacing: '4px',
//                                 letterSpacing: '2px',
//                             },
//                         },
//                         "& .MuiInputLabel-root": {
//                             color: ColorHueso,
//                         },
//                         "& .MuiInputLabel-root.Mui-focused": {
//                             color: ColorGris,
//                         },
//                     }}
//                 />
//                 {/* <Typography
//                         component="div"
//                         align="justify"
//                         gutterBottom
//                         color={ColorGris}
//                         sx={{
//                             typography: { xs: 'h5', md: 'h6' }
//                         }}>
//                         {charCount}/200 caracteres.
//                     </Typography> */}

//                 <Button
//                     variant="contained"
//                     color="success"
//                     size='Large'
//                 >
//                     Enviar Correo
//                 </Button>
//             </Box>
//         </Box>
//     </>
// )


// export default Contact