import React, { useState } from 'react';
import Navbar from './components/Nav.jsx';
import Description from './components/Description/Description.jsx';
import Information from './components/Information/Information.jsx';

import './App.css'
import { color } from '@mui/system';
import Contact from './components/Contact/Contact.jsx';

const ConsultaComponent = () => {
    const colors = {
        ColorMorado: '#31304D', /* COLOR MORADO */
        ColorGris: '#B6BBC4', /* COLOR GRIS */
        ColorHueso: '#F0ECE5', /* COLOR HUESO */
        ColorMarino: '#161A30' /* COLOR MARINO */
    };

    return (
        <div>
            <Navbar />
            <Description />
            <Information colors={colors} />
            <Contact colors={colors} />
        </div>
    );
};

export default ConsultaComponent;
// import React, { useState } from 'react';
// import nav from './components/nav';

// const ConsultaComponent = () => {
//     const [loading, setLoading] = useState(false);
//     const [data, setData] = useState(null);
//     const [error, setError] = useState(null);

//     const handleConsulta = async () => {
//         setLoading(true);
//         setError(null); // Reiniciar error al iniciar la consulta

//         try {
//             const response = await fetch('https://www.je-innovate.com/backend/getData.php'); // Cambia esta URL por la de tu API
//             if (!response.ok) {
//                 throw new Error('Error en la consulta');
//             }
//             const result = await response.json();
//             setData(result);
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <button onClick={handleConsulta}>Consultar</button>
//             {loading && <p>Cargando...</p>}
//             {error && <p>Error: {error}</p>}
//             {data && <pre>{JSON.stringify(data, null, 2)}</pre>} {/* Mostrar resultados */}
//         </div>
//     );
// };

// export default ConsultaComponent;