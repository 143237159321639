import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import fondo from '../../img/fondo.png';
import bootstrap from '../../img/bootstrap.png';
import codeigniter from '../../img/codeigniter.svg';
import css from '../../img/css.svg';
import html from '../../img/html.png';
import jquery from '../../img/jquery.png';
import js from '../../img/js.png';
import mercadopago from '../../img/mercadopago.png';
import mysql from '../../img/mysql.png';
import php from '../../img/php.png';

const Information = ({ colors }) => {
    const { ColorMorado, ColorGris, ColorHueso, ColorMarino } = colors;

    return (
        <>
        <Box sx={{
            width: '100%',
            height: 'auto',
            minHeight: '720px',
            bgcolor: ColorMorado, // Color de fondo general
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Box
                sx={{
                    p: 4,
                    width: { xs: '100%', md: '80%' },
                    color: ColorGris, // Color del texto general
                }}>
                <Typography variant="body1"
                    sx={{
                        textAlign: 'justify',
                        typography: { xs: 'body2', md: 'h6' }
                    }}
                >
                    Durante el año 2024, he trabajado como desarrollador full-stack. En el Back-End, he utilizado PHP bajo el modelo MVC, asegurando una arquitectura robusta y mantenible. En el Front-End, he implementado JavaScript, jQuery, y AJAX para la gestión dinámica de vistas y una experiencia de usuario fluida. Además, he incorporado pasarelas de pago como Stripe y Mercado Pago, garantizando transacciones seguras y confiables en los sistemas desarrollados.
                </Typography>

                <Typography variant="body1"
                    sx={{
                        paddingTop: 4,
                        textAlign: 'justify',
                        typography: { xs: 'body2', md: 'h6' }
                    }}
                >
                    Los siguientes trabajos, son proyectos que he realizado con base en los conocimientos y habilidades que he adquirido durante el tiempo que he estado trabajando.
                </Typography>

                {/* DIV DE PROYECTOS */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    {[1, 2, 3, 4].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: { xs: '95%', md: '40%', lg: '600px' },
                                height: '350px',
                                backgroundImage: `url(${fondo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                margin: '0 auto',
                                marginTop: 4,
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.8)',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '30%',
                                    backgroundColor: '#F0ECE5C7', // Color de fondo de las tarjetas
                                    position: 'absolute',
                                    bottom: 10,
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    p: 1
                                }}
                            >
                                {/* Primera mitad: Título */}
                                <Box
                                    sx={{
                                        width: '35%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: ColorMarino,
                                        typography: { xs: 'button', md: 'h6' }
                                    }}
                                    >
                                        Soy el título
                                    </Typography>
                                </Box>

                                {/* Segunda mitad: Descripción */}
                                <Box
                                    sx={{
                                        width: '65%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // overflowY: 'scroll',
                                    }}
                                >
                                    <Typography sx={{
                                        textAlign: 'center',
                                        color: ColorMarino,
                                        typography: { xs: 'caption', md: 'body2' }
                                    }}
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>

        <Box
        sx={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            bgcolor: ColorHueso, // Color de fondo general
        }}
        >
            <Box
                sx={{
                    width: {xs: '100%', md: '80%'},
                    height: 'auto',
                    minHeight: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    gap: 5,
                    padding: '20px 5px',
                    margin: 'auto',
                }}
            >
                {/* Rombo Bootstrap */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={bootstrap}
                        alt="Bootstrap logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo CodeIgniter */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={codeigniter}
                        alt="CodeIgniter logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo CSS */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={css}
                        alt="CSS logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                    }}
                />
                </Box>

                {/* Rombo HTML */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={html}
                        alt="HTML logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>
                {/* Rombo jQuery */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={jquery}
                        alt="jQuery logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo JavaScript */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={js}
                        alt="JavaScript logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo MercadoPago */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={mercadopago}
                        alt="MercadoPago logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo MySQL */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={mysql}
                        alt="MySQL logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                {/* Rombo PHP */}
                <Box
                    sx={{
                        width: { xs: '15%', md: '90px' },
                        height: { xs: '75px', md: '100px' },
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#31304D2E',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src={php}
                        alt="PHP logo"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    />
                </Box>

                
            </Box>
        </Box>


        </>
        
    )
}

export default Information;

// import React from 'react';
// import { Box } from '@mui/system';
// import Typography from '@mui/material/Typography';
// import fondo from '../../img/fondo.png';

// const Information = () => {
//     return (
//         <Box sx={{
//             width: '100%',
//             height: 'auto',
//             minHeight: '720px',
//             bgcolor: '#31304D',
//             display: 'flex',
//             justifyContent: 'center',
//             // alignItems: 'center',
//         }}>
//             <Box
//                 sx={{
//                     p: 4,
//                     width: { xs: '100%', md: '80%' },
//                     color: '#B6BBC4',
//                 }}>
//                 <Typography variant="body1"
//                     sx={{
//                         textAlign: 'justify',
//                         typography: { xs: 'body2', md: 'h6' }
//                     }}
//                 >
//                     Durante el año 2024, he trabajado como desarrollador full-stack. En el Back-End, he utilizado PHP bajo el modelo MVC, asegurando una arquitectura robusta y mantenible. En el Front-End, he implementado JavaScript, jQuery, y AJAX para la gestión dinámica de vistas y una experiencia de usuario fluida. Además, he incorporado pasarelas de pago como Stripe y Mercado Pago, garantizando transacciones seguras y confiables en los sistemas desarrollados.
//                     Estos trabajos son proyectos personales inspirados en mi experiencia previa, desarrollados con base en los conocimientos y habilidades adquiridos durante mi trayectoria.
//                 </Typography>

//                 <Typography variant="body1"
//                     sx={{
//                         paddingTop: 4,
//                         textAlign: 'justify',
//                         typography: { xs: 'body2', md: 'h6' }
//                     }}
//                 >
//                     Los siguientes trabajos, son proyectos que he realizado con base en los conocimientos y habilidades que he adquirido durante el tiempo que he estado trabajando.
//                     {/* Estos trabajos son proyectos personales inspirados en mi experiencia previa, desarrollados con base en los conocimientos y habilidades adquiridos durante mi trayectoria. */}
//                 </Typography>

//                 {/* DIV DE PROYECTOS */}
//                 <Box
//                     sx={{
//                         display: 'flex',
//                         gap: 2,
//                         flexWrap: 'wrap',
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>

//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>

//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>
//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }

// export default Information